
// querystring lib that supports nested objects
// const qs = require('qs')
const parseUrl = require('url-parse');

export default ({ app, store, route, req }, inject) =>
{

	inject('localiseUrl', (srcUrl, force) =>
	{
		if (!srcUrl)	return srcUrl;

		const url = parseUrl( srcUrl );
		const currentRoute = parseUrl( route.fullPath );

		const host = req ? req.headers.host : window.location.host.split(':')[0];
		const isAcademy = !!force ? false : host.indexOf('academy') == 0;

		const includesDomain = ( srcUrl.indexOf('http')==0 || srcUrl.indexOf('//')==0 );
		let isAbsolute = includesDomain || srcUrl.indexOf('/')==0;

		// console.log(`host: [${host}]` )
		// console.log(`route.fullPath: [${route.fullPath}]` )
		// console.log(`currentRoute.host: [${currentRoute.host}]` )

		// debugger
		if ( isAbsolute && (!isAcademy) )
		{
			let path = url.pathname;

			if ( includesDomain && currentRoute.host != url.host )
				return srcUrl;

			if ( !includesDomain && currentRoute.host != url.host )
				path = url.host + '/' + path;

			// split and remove empty parts
			let parts = path.split('/').filter( x => !!x );
			let hasLocale = (parts.length > 0 && parts[0].length == 2);
			if ( !hasLocale )
			{
				path = '/' + store.getters.lang + path;
			}

			if (!includesDomain)
				return `${path}${url.query}${url.hash}`
			else
			{
				url.set('pathname', path);
				return url.toString();
			}
		}
		else
			return srcUrl;
	})



}
