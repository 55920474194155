import Vue from 'vue';


// NOTE: this calls the 'scroll-to-anchor' event registered by the scroll-to-anchor MIXIN

Vue.directive('smooth-scroll',
{
	// called only once, when the directive is first bound to the element. This is where you can do one-time setup work.
	bind(el, binding, vnode)
	{
		var handleClick = function _handleClick(event)
		{
			// console.log('open-modal handleClick():', event);
			if (event.button == 0)
			{
				event.preventDefault();
				event.stopPropagation();

				vnode.__smoothScrollFn = handleClick;
				// debugger;
				vnode.context.$root.$emit('scroll-to-anchor', event);
				return false;
			}
		};

		if (process.client)
		{
			el.addEventListener('click', handleClick);
		}
	},

	// When the bound element is inserted into the DOM...
	inserted(el, binding, vnode)
	{
	},

	// called only once, when the directive is unbound from the element.
	unbind(el, binding, vnode)
	{
		if (vnode.__smoothScrollFn)
		{
			if (process.client)
			{
				el.removeEventListener('click', vnode.__smoothScrollFn);
			}
		}
	}
})
