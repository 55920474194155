
// querystring lib that supports nested objects
const qs = require('qs')

export default ({ app, store, $axios }, inject) =>
{

	if (process.browser)
	{
		// $axios.defaults.baseURL = window.location.origin + '/';
		// $axios.defaults.browserBaseURL = window.location.origin + '/';
		$axios.setBaseURL(window.location.origin + '/')
	}

	if (process.server)
	{
		$axios.setBaseURL(process.env.APP_URL + '/')
	}

	const apiPrefix = '/api/v1/';
	// $axios.defaults.headers.common['Content-Type'] = 'application/json';
	// $axios.setHeader('Content-Type', 'application/json')

	// params must define "data" and optionally "query" objects
	// "url" is a relative url, not an absolute
	function apiGet(url, params)
	{
		let theUrl = url;
		if ( url.charAt(0) != '/' && url.indexOf('http') != 0)
			theUrl = apiPrefix + theUrl;
		// $axios.setHeader('Content-Type', 'application/json')
		return $axios.get(theUrl, { params });
	}
	inject('apiGet', apiGet)


	function apiPost(url, params, config)
	{
		let theUrl = url;
		if ( url.charAt(0) != '/' && url.indexOf('http') != 0)
			theUrl = apiPrefix + theUrl;
		// $axios.setHeader('Content-Type', 'application/json')
		return $axios.post(theUrl, params, config);
	}
	inject('apiPost', apiPost)


	function apiPut(url, params, config)
	{
		let theUrl = url;
		if ( url.charAt(0) != '/' && url.indexOf('http') != 0)
			theUrl = apiPrefix + theUrl;
		// $axios.setHeader('Content-Type', 'application/json')
		return $axios.put(theUrl, params, config);
	}
	inject('apiPut', apiPut)


	function apiPatch(url, params, config)
	{
		let theUrl = url;
		if ( url.charAt(0) != '/' && url.indexOf('http') != 0)
			theUrl = '/api/v1/' + theUrl;
		// $axios.setHeader('Content-Type', 'application/json')
		return $axios.patch(theUrl, params, config);
	}
	inject('apiPatch', apiPatch)


	function apiDelete(url, params)
	{
		let theUrl = url;
		if ( url.charAt(0) != '/' && url.indexOf('http') != 0)
			theUrl = apiPrefix + theUrl;
		// $axios.setHeader('Content-Type', 'application/json')
		// if (params)
		// {
		// 	// console.log('stringify: ', qs.stringify(params))
		// 	// return $axios.delete(theUrl + '?' + qs.stringify(params) )
		// 	return $axios.delete(theUrl, { data: params,
		// 						// headers: { 'Content-Type': 'application/json' }
		// 					});
		// }
		// else
		// 	return $axios.delete(theUrl)
		return $axios.delete(theUrl, { data: params })
	}
	inject('apiDelete', apiDelete)

	function apiGetTaxonomy(params)
	{
		const lang_code = store.getters.lang
		var options = { locale: lang_code };
		if (params && typeof params == "string")
			options.parent_key = params;
		if (params && typeof params == "object")
			options = {...options, ...params};

		return apiGet('taxonomy', options);
	}
	inject('apiGetTaxonomy', apiGetTaxonomy)


	function apiMail(params, config)
	{
		return apiPost('mailing-list', params, config)
	}
	inject('apiMail', apiMail)



	// inject('apiLoadMenuData', function()
	// {
	// 	const api = app.$prismic.api;

	// 	return api.query(
	// 		app.$prismic.predicates.at('document.type', 'menu'),
	// 		{ lang : store.getters.lang }
	// 		// { lang : '*' }
	// 	)
	// });


	// inject('apiLoadCommonWordData', function()
	// {
	// 	const api = app.$prismic.api;

	// 	return api.query(
	// 		app.$prismic.predicates.at('document.type', 'common_text'),
	// 		{ lang : store.getters.lang }
	// 		// { lang : '*' }
	// 	)
	// });

	inject('apiLoadStringsData', function()
	{
		const lang_code = store.getters.lang

		return apiGet('string', { locale: lang_code })
	});

	// inject('apiLoadCourseData', function(uid)
	// {
	// 	const api = app.$prismic.api;

	// 	return api.query(
	// 		app.$prismic.predicates.at('my.training-course-page.uid', uid),
	// 		// [
	// 		// 	app.$prismic.predicates.at('document.type', 'training-course-page'),
	// 		// 	app.$prismic.predicates.at('document.uid', uid),
	// 		// ],
	// 		{ lang : store.getters.lang }
	// 		// { lang : '*' }
	// 	)
	// });
}
