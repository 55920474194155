const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;;

export default {

	// this is internal, use the Action instead
	setBrowserBaseUrl(state, url)
	{
		state.browserBaseUrl = url || '';
	},

	setAnalyticsIds(state, payload)
	{
		state.FB_PIXEL_ID = payload.fb_pixel || '';
		state.GA_ID = payload.ga || '';
		state.GTAG_ID = payload.gtag || '';
	},

	// this is internal, use the Action instead
	set_lang(state, { lang })
	{
		state.lang = lang || 'en';
	},

	setHost(state, url)
	{
		state.host = url;
	},

	setOriginUrl(state, url)
	{
		state.originUrl = url;
	},

	setLangList(state, { list })
	{
		// let array = [...list];
		// array.sort( (a, b) =>
		// {
		// 	if (a.display_order == -1 || !Number.isInterer(a.display_order) )
		// 	{
		// 		if (b.display_order == -1 || !Number.isInterer(b.display_order) )
		// 		{
		// 			return 0;	// they are the same
		// 		}
		// 		return 1;	// a is after b
		// 	}
		// 	if (b.display_order == -1 || !Number.isInterer(b.display_order) )
		// 	{
		// 		return -1;		// a is before b
		// 	}
		// 	return a.display_order - b.display_order;
		// } )
		// state.langList = array;
		state.langList = list;
	},

	clearSeoPageEntries(state)
	{
		state.seoPageEntries = [];
	},

	addSeoPageEntry(state, url)
	{
		state.seoPageEntries.push(url);
	},

	setMobileMenuVisible(state, show)
	{
		state.mobileMenuVisible = show;
	},

	setPageMenu(state, menu)
	{
		if (menu && !Array.isArray(menu))
			state.pageMenu = menu.json || [];
		else
			state.pageMenu = menu;
	},

	setPageFooter(state, footer)
	{
		if (footer && !Array.isArray(footer))
			state.pageMenu = footer.json || [];
		else
			state.pageFooter = footer;
	},

	setEditingPage(state, { editing })
	{
		consola.debug('setEditingPage: ', editing)
		let y = 0;
		if (typeof window !=='undefined')
			y = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
		state.isEditingPage = editing || false;

		if (typeof window !=='undefined')
		{
			setTimeout( ()=>
			{
				if (document.documentElement.scrollTop)	document.documentElement.scrollTop = y;
				if (window.pageYOffset)					window.pageYOffset = y;
				if (document.body.scrollTop)			document.body.scrollTop = y;
			}, 0);
		}
	},

	setTopBanner(state, payload)
	{
		state.topBanner = payload;
	},

	setWhiteLogo(state, payload)
	{
		state.whiteLogo = payload;
	},

	// set the *shared* site data, like menu/footer/analytics/etc
	setSiteData(state, data)
	{
		state.siteData = data;
	},

	// setMenuData(state, data)
	// {
	// 	state.menuData = data;
	// },

	// setMobileMenuData(state, data)
	// {
	// 	state.mobileMenuData = data;
	// },

	// setFooterData(state, data)
	// {
	// 	state.footerData = data;
	// },

	setAdminPanelRef(state, { data })
	{
		// WARNING: object *MUST* frozen, otherwise stack-overflow witll occur
		state.adminPanelRef = Object.freeze( {ref: data} );
	},

	// setCommonWords(state, { data })
	// {
	// 	state.commonWords = data;
	// },

	setStringTable(state, { data })
	{
		const list = data;
		let dict = {};
		// debugger;
		for (let i = 0; i < list.length; i++)
		{
			const item = list[i]
			// dict[item.key] = {
			// 	// id: item.id,
			// 	// values: item.values
			// 	id: item.string_id,
			// 	value: item.value
			// };
			dict[item.key] = item;
		}
		state.stringTable = dict;
	},

	setString(state, payload)
	{
		// debugger
		if (Array.isArray( payload ) )
			payload = payload[0];
		if ( !Array.isArray( payload ) )
			payload = [ payload ];
		for (let i = 0; i < payload.length; i++)
		{
			const key = payload[i].key;
			const value = payload[i].value || payload[i].values[0].value;
			// state.stringTable[key].values[0].value = value;
			state.stringTable[key].value = value;

			// force refersh
			let temp = state.stringTable[key];
			state.stringTable[key] = {};
			state.stringTable[key] = temp;
			// do not copy
			// state.stringTable[key] = { ...state.stringTable[key] }
		}
	},

	setWindowWidth(state, payload)
	{
		state.window_width = payload;
	},

	setScrollTop(state, payload)
	{
		state.window_scroll_top = payload;
	},

	setGdprConsent(state, payload)
	{
		state.gdprConsent = payload;
	},
}
