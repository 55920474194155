import Vue from "vue";
import VueGtag from "vue-gtag";


export default ({ app }, inject) =>
{
	// console.log('gtag router: ', app.router)

	Vue.use(VueGtag, {
					bootstrap: false
				}, 
				app.router
			);
}
