import Vue from 'vue'
import rafLoop from 'raf-loop'

// const rafengine = process.client ?
// 						rafLoop().start() :
// 						{ start(){}, stop(){}, on(){}, removeEventListener(){} };


if (process.client)
{
	Vue.prototype.$raf = rafLoop().start();
}
else
{
	Vue.prototype.$raf = {
		start() {},
		stop() {},
		on() {},
		once() {},
		off() {},
		addListener() {},
		removeListener() {},
		removeAllListeners() {},
	};
}
