export default () => (
{
	browserBaserUrl: process.env.APP_URL || (typeof window!='undefined' ? window.location.origin : ''),
	originUrl: process.env.APP_URL || (typeof window!='undefined' ? window.location.origin : ''),
	host: '',
	auth_token: null,
	isEditingPage: false,
	gdprConsent: null,
	FB_PIXEL_ID: '',
	GA_ID: '',
	GTAG_ID: '',
	// lang: 'en-us', //'el-gr',
	lang: 'el', //'el-gr',
	langList: [],
	seoPageEntries: [],
	pageMenu: [],
	pageFooter: [],
	topBanner: null,
	whiteLogo: false,
	mobileMenuVisible: false,
	menuData: null,
	mobileMenuData: null,
	footerData: null,
	siteData: {},
	adminPanelRef: null,
	// commonWords: null,
	stringTable: {},
	window_width: -1,
	window_scroll_top: 0,
})
