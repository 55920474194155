import Vue from 'vue'
// import rafLoop from 'raf-loop'
import { quinticInOut } from '@mozillareality/easing-functions'
import lerp from 'lerp'

// function lerp(v0, v1, t)
// {
// 	return v0*(1-t)+v1*t
// }

// const rafengine = process.client ?
// 						rafLoop().start() :
// 						{ start(){}, stop(){}, on(){}, removeEventListener(){} };

const DURATION_MS = 1000;
const easingFunc = quinticInOut;


if (process.client)
{
	Vue.prototype.$scrollTo = function(destVal)
	{
		// console.log('$raf type:', typeof this.$raf);
		// const startDate = new Date().getTime();
		const from = window.scrollY || document.body.scrollTop;
		// console.log(`scrollTo: from[${from}] to[${destVal}]`)
		destVal = destVal || 0;
		if (from == destVal)	return;

		const self = this;
		let t = 0;

		function onTick(dt)
		{
			let willStop = false;
			t += (dt / DURATION_MS);
			willStop = (t >= 1.0);
			t = Math.min(t, 1.0);
			let pos = lerp( from, destVal, easingFunc(t) );
			window.scrollTo(0, pos);
			document.body.scrollTo(0, pos);

			if (willStop)
			{
				self.$raf.removeListener('tick', onTick);
			}
		}

		this.$raf.addListener('tick', onTick);
	};
}
else
{
	Vue.prototype.$scrollTo = function() {};
}
