import Vue from 'vue'
const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import Vuetify from 'vuetify/lib'

consola.debug('loading vuetify plugin...')
Vue.use(Vuetify, {
	treeShake: true
})

export default (ctx) => {
	// const vuetifyOptions = typeof options === 'function' ? options(ctx) : options

//   <% if (options.defaultIconPreset) { %>
// 	vuetifyOptions.icons = vuetifyOptions.icons || {}
// 	vuetifyOptions.icons.iconfont = '<%= options.defaultIconPreset %>'
//   <% } %>
//   <% if (options.preset) { %>
// 	vuetifyOptions.preset = preset
//   <% } %>

	// const vuetify = new Vuetify(vuetifyOptions)
	const vuetify = new Vuetify()

	ctx.app.vuetify = vuetify
	ctx.$vuetify = vuetify.framework
  }
