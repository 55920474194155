import Vue from 'vue'
// import rafLoop from 'raf-loop'

let hasRegistered = false;

if (process.client)
{
	Vue.prototype.$registerScrollTopListener = function(topY)
	{
		if (typeof topY == 'undefined')
			topY = 5;

		if (hasRegistered)
		{
			console.log('registerScrollTopListener: already registered');
			return;
		}

		let prevScroll = 0;
		const self = this;

		let unwatch = this.$watch('$store.getters.scrollTop', (newVal) =>
		{
			// console.log('scrollTop: ', newVal)
			if (prevScroll != newVal)
			{
				if (newVal <= topY)
				{
					self.$root.$emit('setScrolledToTop', true, newVal);
				}
				else
				{
					if (prevScroll <= topY)
						self.$root.$emit('setScrolledToTop', false, newVal);
				}
				prevScroll = newVal;
			}
		})

		// function handleScroll(dt)
		// {
		// 	if (prevScroll != window.scrollY)
		// 	{
		// 		if (window.scrollY <= topY)
		// 		{
		// 			self.$root.$emit('setScrolledToTop', true, window.scrollY);
		// 		}
		// 		else
		// 		{
		// 			if (prevScroll <= topY)
		// 				self.$root.$emit('setScrolledToTop', false, window.scrollY);
		// 		}
		// 		prevScroll = window.scrollY;
		// 	}
		// }

		// this.$raf.addListener('tick', handleScroll);


		hasRegistered = true;
	};
}
else
{
	Vue.prototype.$registerScrollTopListener = function() {};
}
