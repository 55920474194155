export default {
	async nuxtServerInit (store, ctxt)
	{
		console.log('nuxtServerInit... ');
		// if (req.session.user)
		// {
		// 	commit('user', req.session.user)
		// }

		// store.commit('setMenuData', { data: "nuxtServerInit" })

		try {
			// commit('setMenuData', { data: ctxt.app.$prismic })
			// console.log('ctxt: ', ctxt);
			// console.log('api: ', api);

			// console.log('headers: ', JSON.stringify(ctxt.req.headers))
			const host = ctxt.req ? ctxt.req.headers.host : '';
			const isAcademy = host.indexOf('academy') == 0;
			store.commit('setHost', host)
			store.commit('setOriginUrl', 'https://'+host)

			// const result = await api.getSingle('menu');

			// const [menuResult, wordResult] = await Promise.all(
			// 		[
			// 			ctxt.app.$apiLoadMenuData(),
			// 			ctxt.app.$apiLoadCommonWordData()
			// 		]);
			// // Load the edit button
			// // if (process.client) window.prismic.setupEditButton()

			// if (menuResult.data)
			// 	commit('setMenuData', { data: menuResult.data })
			// else if (menuResult.results && menuResult.results.length)
			// 	commit('setMenuData', { data: menuResult.results[0].data })

			// if (wordResult.data)
			// 	commit('setCommonWords', { data: wordResult.data })
			// else if (wordResult.results && wordResult.results.length)
			// 	commit('setCommonWords', { data: wordResult.results[0].data })

			// store.commit('setBrowserBaseUrl', ctxt.env.browserBaseURL);

			await Promise.all(
				[
					this.$apiGet('locale')
						.then( (response)=>
						{
							if (response.data)
								store.commit('setLangList', { list: response.data })
						} ),
					this.$apiLoadStringsData()
						.then( (response)=>
						{
							if (response.data)
								store.commit('setStringTable', { data: response.data })
						}),
					this.$apiGet('seo', { owner_url: `@seo` })
						.then( resp =>
						{
							if (!resp || !resp.data)	return;

							// consola.debug(resp.data[0][0])
							const data = JSON.parse( resp.data[0][0].data || '{}' );
							store.commit('setAnalyticsIds', data)
							// site_seo.fb_pixel = data.fb_pixel || '';
							// site_seo.ga = data.ga || '';
							// site_seo.gtag = data.gtag || '';
						})
						.catch( error =>
						{
							console.warning('analytic ids error: ', error);
						})
				]);

		}
		catch (e)
		{
			// commit('setMenuData', { data: e })
			ctxt.error({ statusCode: 404, message: 'nuxtServerInit: an error occured : '+JSON.stringify(e) })
		}
	},

	setLang(ctxt, { lang = null })
	{
		console.log('action: setLang: ', lang);
		if (lang.length != 2)
		{
			console.log(`invalid locale [${lang}]`)
			return;
		}
		if (ctxt.getters.lang == lang)
		{
			console.log('lang is already the same...');
			return;
		}
		ctxt.commit('set_lang', { lang });

		this.$apiLoadStringsData()
			.then( (response)=>
			{
				if (response.data)
					ctxt.commit('setStringTable', { data: response.data })
				// else if (response.results && response.results.length)
				// 	ctxt.commit('setCommonWords', { data: response.results[0].data })
			});
	},

	setDeviceWidthFromWindow(ctxt)
	{
		if (typeof window !== 'undefined')
		{
			ctxt.commit('setWindowWidth', window.innerWidth)
		}
	},

	setDeviceWidthFromUserAgent(ctxt)
	{
		if (this.$device.isMobile)
		{
			ctxt.commit('setWindowWidth', 375) // iphone 6/7/8 display res
		}
		else if (this.$device.isTablet)
		{
			ctxt.commit('setWindowWidth', 768) // ipad display res
		}
		else if (this.$device.isDesktop)
		{
			ctxt.commit('setWindowWidth', 1440) // macbook 13'' display res
		}
		else
		{
			ctxt.commit('setWindowWidth', 1024)
		}
	},

	fetchAnalytics(ctxt)
	{
		return this.$apiGet('seo', { owner_url: `@seo` })
			.then( resp =>
			{
				if (!resp || !resp.data)	return;

				// consola.debug(resp.data[0][0])
				const data = JSON.parse( resp.data[0][0].data || '{}' );
				ctxt.commit('setAnalyticsIds', data)
				// site_seo.fb_pixel = data.fb_pixel || '';
				// site_seo.ga = data.ga || '';
				// site_seo.gtag = data.gtag || '';
			})
			.catch( error =>
			{
				console.warning('analytic ids error: ', error);
			})
	}
}
