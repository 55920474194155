import windowWidth from "../plugins/window-width";

// const langList = [
// 	{code: 'en-us', name: 'English', short: 'EN'},
// 	{code: 'el-gr', name: 'Greek', short: 'EL'},
// 	{code: 'fr-fr', name: 'French', short: 'FR'},
// ]


export default {

	browserBaserUrl(state)
	{
		return state.browserBaserUrl;
	},

	lang(state)
	{
		return state.lang;
	},

	allLanguages(state)
	{
		return state.langList;
	},

	langName(state)
	{
		if (!state.langList.length)
			console.log('langName: langList.length: ', state.langList.length)
		for (var i = 0; i < state.langList.length; i++)
		{
			if (state.lang == state.langList[i].lang_code)
				return state.langList[i].name;
		}

		return '';
	},


	getLangNameWithId(state)
	{
		return id =>
		{
			if (!state.langList.length)	return '';
			for (var i = 0; i < state.langList.length; i++)
			{
				if (id == state.langList[i].id)
					return state.langList[i].lang_code;
					// return state.langList[i].name;
			}

			return '';
		}
	},

	langShort(state)
	{
		if (!state.langList.length)
			console.log('langShort: langList.length: ', state.langList.length)
		for (var i = 0; i < state.langList.length; i++)
		{
			if (state.lang == state.langList[i].lang_code)
				return state.langList[i].lang_code;
		}

		return '';
	},

	fb_pixel_id(state) {	return state.FB_PIXEL_ID; },
	ga_id(state) {	return state.GA_ID; },
	gtag_id(state) {	return state.GTAG_ID; },

	originUrl(state)
	{
		return state.originUrl;
	},

	host(state)
	{
		return state.host;
	},

	mobileMenuVisible(state)
	{
		return state.mobileMenuVisible;
	},

	seoPageEntries(state)
	{
		return state.seoPageEntries;
	},

	isEditingPage(state)
	{
		return state.isEditingPage;
	},

	topBanner(state)
	{
		return state.topBanner;
	},

	hasWhiteLogo(state)
	{
		return state.whiteLogo;
	},

	siteData(state)
	{
		return state.siteData || {};
	},

	menuData(state)
	{
		return state.pageMenu || [];
		// return state.siteData.menu || {};
		// return state.menuData;
	},

	mobileMenuData(state)
	{
		return state.pageMenu || [];
		return state.siteData.mobile_menu || state.state.siteData.menu || {};
		// return state.mobileMenuData || state.state.menuData;
	},

	footerData(state)
	{
		return state.pageFooter || [];
		return state.siteData.footer || {};
		// return state.footerData;
	},

	adminPanelRef(state)
	{
		return state.adminPanelRef ? state.adminPanelRef.ref : null;
	},

	// commonWords(state)
	// {
	// 	return state.commonWords;
	// },

	stringTable(state)
	{
		return state.stringTable;
	},

	getString(state)
	{
		return (str) => {
			var obj = state.stringTable[str] || state.stringTable[str.toUpperCase()];
			// console.log(`${str} => ${obj ? obj.value : "[undefined]"}`)
			return obj ? obj.value : str;
		}
	},

	getStringId(state)
	{
		return (str) =>
		{
			var obj = state.stringTable[str] || state.stringTable[str.toUpperCase()];
			return obj ? obj.id : -1;
		}
	},

	windowWidth(state)
	{
		return state.window_width;
	},

	scrollTop(state)
	{
		return state.window_scroll_top;
	},

	scrollBottom(state)
	{
		if (typeof window == 'undefined')	return 976;
		return (document.body.clientHeight - window.innerHeight) - state.window_scroll_top;
	},

	gdprConsent(state)
	{
		return state.gdprConsent;
	},

	isMobile(state)
	{
		return state.window_width <= 575;
	},

	isTablet(state)
	{
		return state.window_width <= 850;
	},

	isTabletOrBigger(state)
	{
		return state.window_width > 575;
	},

	isLargerThanTabletLandscape(state)
	{
		return state.window_width > 1024;
	},

	isLaptop(state)
	{
		return state.window_width > 850 && state.window_width <= 1200;
	},

	isLaptopOrBigger(state)
	{
		return state.window_width > 850;
	},

	isDesktop(state)
	{
		return state.window_width > 1200;
	},
}
