import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export function createRouter(ssrContext, createDefaultRouter, routerOptions)
{
	// console.log('custom router...')

	const options = routerOptions || createDefaultRouter(ssrContext).options;
	const hostname = ssrContext ? ssrContext.req.headers.host : location.host;

	// console.log(options.routes);

	return new Router({
		...options,
		// routes: options.routes,
		routes: fixRoutes(options.routes, hostname),
	});
}

const ACADEMY_SUBDOMAIN = 'academy';

function fixRoutes(defaultRoutes, hostname)
{
	let routes = [];

	// console.log('defaultRoutes: ', JSON.stringify(defaultRoutes) );

	// if (hostname.includes(process.env.ACADEMY_SUBDOMAIN))
	if (hostname.includes(ACADEMY_SUBDOMAIN))
		routes = academyRoutes(defaultRoutes);
	else
		routes = mainDomainRoutes(defaultRoutes);
	// if (hostname.includes('subdomain2')) return subdomain2Routes(defaultRoutes);
	// console.log('routes:', routes);
	return routes;
}

function academyRoutes(defaultRoutes)
{
	const routes = defaultRoutes.filter(r => r.path.indexOf(`/${ACADEMY_SUBDOMAIN}/`) == 0 || r.path.indexOf(`/main-domain/`) != 0 )
	routes.forEach(r =>
		{
			if (r.path.indexOf(`/${ACADEMY_SUBDOMAIN}/`) == 0)
				r.path = r.path.replace(`/${ACADEMY_SUBDOMAIN}/`, '/');
			else if (r.path==`/${ACADEMY_SUBDOMAIN}`)
				r.path = '/';
		})

	return routes;

	// // const route = defaultRoutes.find(r => r.name === 'subdomain1');
	// const route = defaultRoutes.find(r => r.path.indexOf(`/${process.env.ACADEMY_SUBDOMAIN}/`) == 0);
	// route.path = route.path.replace(`/${process.env.ACADEMY_SUBDOMAIN}/`, '/');
	// return [route];
}

function mainDomainRoutes(defaultRoutes)
{
	// debugger;
	// let routes = defaultRoutes.filter(r => r.path.indexOf(`/main-domain/`) == 0
	// 							|| r.path.indexOf(`/${ACADEMY_SUBDOMAIN}/page`) == 0);

	const routes = defaultRoutes.filter(r => r.path.indexOf(`/main-domain/`) == 0
			|| r.path.indexOf(`/${ACADEMY_SUBDOMAIN}/page`) == 0
			|| ( r.path!=(`/${ACADEMY_SUBDOMAIN}`) && r.path.indexOf(`/${ACADEMY_SUBDOMAIN}/`) != 0)
		)
	routes.forEach(r =>
		{
			// /:lang?
			if (r.path.indexOf(`/${ACADEMY_SUBDOMAIN}/page`) == 0 )
			{
				r.path = r.path.replace(`/${ACADEMY_SUBDOMAIN}/page`, '/:lang?/page');
				return;
			}
			if (r.path.indexOf(`/main-domain/`) == 0)
				r.path = r.path.replace(`/main-domain/`, '/');
		})

	return routes;

	// // const route = defaultRoutes.find(r => r.name === 'subdomain1');
	// const route = defaultRoutes.find(r => r.path.indexOf(`/${process.env.ACADEMY_SUBDOMAIN}/`) == 0);
	// route.path = route.path.replace(`/${process.env.ACADEMY_SUBDOMAIN}/`, '/');
	// return [route];
}
