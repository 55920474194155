// var ua = navigator.userAgent.toLowerCase();
// if (ua.indexOf('trident') > -1)
// {
// 	if (typeof document != 'undefined')
// 		document.documentElement.className += ' browser-trident';
// }

function isChrome()
{
	if(process.browser)
		// true;
		return !isEdge() && /Google Inc/.test(navigator.vendor);
	else
		false;
}

function isChromeIOS()
{
	if(process.browser)
		// true;
		return /CriOS/.test(navigator.userAgent);
	else
		false;
}

function isTrident()
{
	if(process.browser)
		// true;
		return navigator.userAgent.toLowerCase().indexOf('trident') > -1;
	else
		false;
}

function isEdge()
{
	if(process.browser)
		// true;
		return navigator.userAgent.indexOf("Edg/") > -1 || navigator.userAgent.indexOf("Edge/") > -1;
	else
		false;
}

function isSafari()
{
	if(process.browser)
	{
		// true;
		// return navigator.vendor.toLowerCase().indexOf('apple') > -1;
		return /constructor/i.test(window.HTMLElement) ||
				(function(p) {
					return p.toString() === "[object SafariRemoteNotification]";
				})(!window["safari"] || safari.pushNotification)
				|| navigator.vendor.toLowerCase().indexOf('apple') > -1;
	}
	else
		false;
}

function isFirefox()
{
	if(process.browser)
		// true;
		return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
	else
		false;
}

function isYandex()
{
	if(process.browser)
		// true;
		return /yabrowser/.test(navigator.userAgent.toLowerCase());
	else
		false;
}

export default () =>
{
	if (process.browser && typeof window !== 'undefined')
	{
		let browserDetection = [];//{};
		if (isChrome()) browserDetection.push('browser-chrome');
		if (isChromeIOS()) browserDetection.push('browser-chrome-ios');
		if (isTrident()) browserDetection.push('browser-trident');
		if (isEdge()) browserDetection.push('browser-edge');
		if (isSafari()) browserDetection.push('browser-safari');
		if (isYandex()) browserDetection.push('browser-yandex');

		// document.documentElement.class += ' '+browserDetection.join(' ');
		document.body.classList.add(browserDetection.join(' '))
		// if (process.browser)
		// {
		// 	// browserDetection =
		// 	// {
		// 	// 	'browser-chrome': isChrome(),
		// 	// 	'browser-chrome-ios': isChromeIOS(),
		// 	// 	'browser-trident': isTrident(),
		// 	// 	'browser-edge': isEdge(),
		// 	// 	'browser-safari': isSafari(),
		// 	// 	'browser-yandex':  isYandex(),
		// 	// };
		// }
	}
}



export { isChrome, isChromeIOS, isTrident, isEdge, isSafari, isFirefox, isYandex }
