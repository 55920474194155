import Vue from 'vue';


Vue.directive('open-modal',
{
	// called only once, when the directive is first bound to the element. This is where you can do one-time setup work.
	bind(el, binding, vnode)
	{
		var handleClick = function _handleClick(event)
		{
			// console.log('open-modal handleClick():', event);
			if (event.button == 0)
			{
				let href = event.target.getAttribute('href') || '';

				if (href.indexOf('iframe:') == 0)
				{
					let url = href.replace('iframe:', '')

					if (event)
					{
						event.stopPropagation();
						event.preventDefault();
					}

					vnode.context.$root.$emit( 'show-external-form', url);
					return;
				}

				// window.location.host
				if (href.indexOf('http') > -1 && href.indexOf(window.location.host) == -1)
				{
					return;
				}

				event.preventDefault();
				event.stopPropagation();

				vnode.__openModelFn = handleClick;
				vnode.context.$gtag &&
				vnode.context.$gtag.event( 'open', { event_category:'Modal', event_label:  event.target.getAttribute('href') || '', value:  1 });
				vnode.context.$root.$emit('open-modal', event.target);
				return false;
			}
		};

		if (process.client)
		{
			el.addEventListener('click', handleClick);
		}
	},

	// When the bound element is inserted into the DOM...
	inserted(el, binding, vnode)
	{
	},

	// called only once, when the directive is unbound from the element.
	unbind(el, binding, vnode)
	{
		if (vnode.__openModelFn)
		{
			if (process.client)
			{
				el.removeEventListener('click', vnode.__openModelFn);
			}
		}
	}
})
